// @ts-ignore
/* eslint-disable */
//import { request } from 'umi';
// import request from 'umi-request';
import request from '@/utils/request'
import { history } from 'umi';
import { stringify } from 'querystring';
import {  message } from 'antd';
import { MenuListResponse } from '../admin/permission-manager';
import { IStringResponse } from '../game-ops/spellai/feeds-config';


// request.interceptors.request.use((url, options) => {
//   let token = localStorage.getItem('token');
//   if (null === token) {
//       token = '';
//   }
//   const authHeader = { 'token': token };
//   return {
//     url: url,
//     options: { ...options, interceptors: true, headers: authHeader },
//   };
// });

// request.interceptors.response.use(async (response, options) => {
//   let result;
//   const data = await response.clone().json();
//   if (data.code == '401' || data.code == '300') {
//     history.push('/user/login')
//     location.reload();
//   }else if (data.status == 400){
//     message.error('Network error')
//   }else if (data.code == '402'){
//     message.error("无权限")
//   } else {
//     result = response;
//   }
//   return result;
// });

const arrTrans = (num: number, arr: any[]) => {
  const newArr = [];
  while (arr.length > 0) {
      newArr.push(arr.splice(0, num));
  }
  return newArr;
};

/** 获取个人菜单列表 */
export async function queryMenuData() {
  return request<MenuListResponse>('/api/sys/menu/listByName');
}


/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/auth/user/info', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/auth/logout', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('/auth/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

export async function submitCreatAdTasks(body: API.DataSourceType[],accountId:string|undefined,advertiserId:string|undefined, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/ad/adtaskqueue/add', {
    method: 'POST',
    params: {
      'accountId':accountId,
      'advertiserId':advertiserId,
    },
    data: body,
    ...(options || {}),
  });
}

export async function submitUnityCreatAdTasks(body: API.UnityDataSourceType[],accountId:string|undefined, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/ad/adtaskqueue/addUnity', {
    method: 'POST',
    params: {
      'accountId':accountId,
    },
    data: body,
    ...(options || {}),
  });
}

export async function submitMintegralCreatAdTasks(body: API.MintegralDataSourceType[],accountId:string|undefined, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/ad/adtaskqueue/addMintegral', {
    method: 'POST',
    params: {
      'accountId':accountId,
    },
    data: body,
    ...(options || {}),
  });
}

export async function submitPushTasks(body: API.PushQueueParams, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/material/pushqueue/add', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function renderQueue(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.MaterialRenderQueueList>('/api/material/renderqueue/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}

export async function adTaskQueue(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.AdTaskQueueList>('/api/ad/adtaskqueue/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}

export async function pushQueue(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.MaterialPushQueueList>('/api/material/pushqueue/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}

export async function gameModuleList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.GameModuleList>('/api/material/gamemodule/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}

export async function creativeList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
 
) {
  return request<API.CreativeList>('/api/material/creative/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}

export async function materialList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.CreativeList>('/api/material/material/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
      noSpin: true
    },
    ...(options || {}),
  });
}


export async function getFileID(
  path:string,
  name:string
) {
  let result = await request('/api/material/material/fileid', {
    method: 'GET',
    params: {
      'path':path,
      'name':name,
      noSpin: true
    },
  })
  return result;
}

export async function getFileProps(
  path:string,
  name:string
) {
  let result = await request('/api/material/material/fileProps', {
    method: 'GET',
    params: {
      'path':path,
      'name':name,
      noSpin: true
    },
  })
  return result;
}

export async function getEnum(
  enumType:string,
  path?: string
) {
  let result = await request('/api/common/enums', {
    method: 'GET',
    params: {
      'enumType':enumType,
      'path': path,
      noSpin: true
    },
  })
  return result.data;
}

export async function getMaterialTree(
  gameName:string,
  platform:string
) {
  let result = await request('/api/material/material/tree', {
    method: 'GET',
    params: {
      'gameName':gameName,
      'platform':platform,
      noSpin: true
    },
  })
  return result.data;
}

export async function getMaterialCountryTree(
  gameName:string,
  platform:string
) {
  let result = await request('/api/material/material/treeInCountry', {
    method: 'GET',
    params: {
      'gameName':gameName,
      'platform':platform,
      noSpin: true
    },
  })
  return result.data;
}

export async function getEndcardTree(
  gameName:string,
  platform:string
) {
  let result = await request('/api/material/material/treeEndcard', {
    method: 'GET',
    params: {
      'gameName':gameName,
      'platform':platform,
      noSpin: true
    },
  })
  return result.data;
}

export async function getPlayableTree(
  gameName:string,
  platform:string
) {
  let result = await request('/api/material/material/treePlayable', {
    method: 'GET',
    params: {
      'gameName':gameName,
      'platform':platform,
      noSpin: true
    },
  })
  return result.data;
}

export async function getAdTextTree(
  dictType:string,
) {
  let result = await request('/api/dict/external/tree', {
    method: 'GET',
    params: {
      'dictType':dictType,
      noSpin: true
    },
  })
  return result.data;
}


export async function getAdTextList(
  params:string[],
) {
  let result = await request('/api/dict/external/content', {
    method: 'GET',
    params: {
      'params':params,
      noSpin: true
    },
  })
  return result.data;
}

export async function getEnumKV(
  enumType:string
) {
  let result = await request('/api/common/enumsKV', {
    method: 'GET',
    params: {
      'enumType':enumType,
      noSpin: true
    },
  })
  return result.data;
}

export async function getApps(
  platformAccountId:string,
  advertiserId:string
) {
  let result = await request('/api/asserts/platformaccount/listApps', {
    method: 'GET',
    params: {
      'platformAccountId':platformAccountId,
      'advertiserId':advertiserId,
      noSpin: true
    },
  })
  return result.data;
}

export async function queryBudgetList(
  platformAccountId:string[] | string,
  campaignSetId:string
) {
  let result = await request('/api/asserts/platformaccount/listBudget', {
    method: 'GET',
    params: {
      'platformAccountId':platformAccountId,
      'campaignSetId':campaignSetId,
      noSpin: true
    },
  })
  
  return result.data;
}

export async function autoFillCampaign(
  platformAccountId:string[] | string,
  campaignSetId:string
) {
  let result = await request('/api/asserts/platformaccount/autoFillCampaign', {
    method: 'GET',
    params: {
      'platformAccountId':platformAccountId,
      'campaignSetId':campaignSetId,
      noSpin: true
    },
  })
  return result
}

export async function autoFillMTGCampaign(
  platformAccountId:string[] | string,
  gameName:string
) {
  let result = await request('/api/asserts/platformaccount/autoFillMTGCampaign', {
    method: 'GET',
    params: {
      'platformAccountId':platformAccountId,
      'gameName':gameName,
      noSpin: true
    },
  })
  return result;
}

export interface IAddBudget {
  total: string | number
  daily?: string | number
  name: string
}
export async function queryAddBudgetList(body: IAddBudget, params: { platformAccountId:string[], campaignSetId:string, }, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/asserts/platformaccount/createUnityBudget', {
    method: 'POST',
    params: {
      ...params,
      noSpin: true
    },
    data: body,
    ...(options || {}),
  });
}

export async function getInstances() {
  let result = await request('/api/ali/acs/all', {
    method: 'GET',
    params: {
      noSpin: true
    },
  })
  return  arrTrans(3,result.data); 
}

export async function stop(instanceId:string) {
  let result = await request('/api/ali/acs/stop', {
    method: 'GET',
    params: {
      'instanceId':instanceId,
      noSpin: true
    },
  })
  return result.data;
}

export async function start(instanceId:string) {
  let result = await request('/api/ali/acs/start', {
    method: 'GET',
    params: {
      'instanceId':instanceId,
      noSpin: true
    },
  })
  return result.data;
}

export async function gen(params:string) {
  let result = await request('/media/gen', {
    method: 'GET',
    params: {
      'params':params,
      noSpin: true
    },
  })
  return result.data;
}

export async function getPlatformAccountTreeData() {
  let result = await request('/api/asserts/platformaccount/tree', {
    method: 'GET',
    params: {
      noSpin: true
    },
  })
  return result;
}

export async function resotre(jobId:string|undefined) {
  let result = await request('/api/ad/adtaskqueue/restore', {
    method: 'GET',
    params: {
      jobId:jobId,
      noSpin: true
    },
  })
  return result.data;
}

export async function getPlatformAccountCasCadeTreeData(platform:String) {
  let result = await request('/api/asserts/platformaccount/cascadeTree', {
    method: 'GET',
    params: {
      platform:platform
    },
  })
  return result.data;
}

export async function changePassword(body: API.SysUserChangePassword, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/sys/user/changPassword', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}
/** 重置密码 */
export async function queryResetPassword(id:number){
  return request.get<IStringResponse>('/api/sys/user/resetPassword',{
    params: {
      id
    }
  })
}

export async function editUser(body: API.CurrentUser, options?: { [key: string]: any }) {
  return request<Record<string,any>>('/api/sys/user/update', {
    method: 'POST',
    data: body,
    ...(options || {}),
  });
}

export async function loginOut()  {
  await outLogin();
  const { query = {}, pathname } = history.location;
  const { redirect } = query;
  // Note: There may be security issues, please note
  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname,
      }),
    });
  }
};

export async function businessLog(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  sort?:any,
  options?: { [key: string]: any },
) {
  return request<API.BusinessLogList>('/api/sys/log/list', {
    method: 'GET',
    params: {
      ...params,
      sort,
    },
    ...(options || {}),
  });
}