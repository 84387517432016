export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '登录',
  'menu.register': '注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.form.basic-form': '基础表单',
  'menu.form.step-form': '分步表单',
  'menu.form.step-form.info': '分步表单（填写转账信息）',
  'menu.form.step-form.confirm': '分步表单（确认转账信息）',
  'menu.form.step-form.result': '分步表单（完成）',
  'menu.form.advanced-form': '高级表单',
  'menu.list': '列表页',
  'menu.list.table-list': '查询表格',
  'menu.list.basic-list': '标准列表',
  'menu.list.card-list': '卡片列表',
  'menu.list.search-list': '搜索列表',
  'menu.list.search-list.articles': '搜索列表（文章）',
  'menu.list.search-list.projects': '搜索列表（项目）',
  'menu.list.search-list.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.emp-career': '员工生涯',
  'menu.account.point': '积分记录',

  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.editor': '图形编辑器',
  'menu.editor.flow': '流程编辑器',
  'menu.editor.mind': '脑图编辑器',
  'menu.editor.koni': '拓扑编辑器',
  //new
  'menu.material': '素材管理',
  'menu.material.material': '素材管理',
  'menu.material.material-render-queue-list': '渲染任务',
  'menu.material.material-render-queue-details': '渲染详情',
  'menu.material.material-push-queue-list': '推送队列',
  'menu.material.material-list': '素材列表',
  'menu.material.game-module-list': '游戏模块',
  'menu.material.creative-list': '创意列表',
  'menu.material.material-localize': '素材本地化',
  'menu.material.BuildAudio': '素材本地化',

  'menu.admin.user-manager': '用户管理',
  'menu.admin.point': '积分记录',
  'menu.admin.user-manager.point': '积分记录',
  'menu.admin.role-manager': '角色管理',
  'menu.admin.permission-manager': '权限管理',
  'menu.admin.business-log': '系统日志',
  'menu.user-manager': '用户管理',

  'menu.asserts': '资产管理',
  'menu.asserts.asserts': '资产管理',
  'menu.asserts.platform-account': '平台账号',
  'menu.asserts.ecs': '云机器',
  'menu.ecs': '云机器',

  'menu.asserts.game-board': '游戏列表',
  'menu.asserts.game-board.sdk-build': 'SDK构建',
  'menu.asserts.game-board.game-add': '游戏添加',
  'menu.asserts.game-board.game-edit': '游戏编辑',

  'menu.promotion.ad-task-queue': '投放队列',
  'menu.ad-task-queue': '投放队列',

  'menu.pointer': '永恒指针',
  'menu.pointer.pointer': '永恒指针',
  'menu.pointer.search': '分析系统',
  'menu.pointer.search.board': '分析看板',
  'menu.pointer.search.subcnl': '子渠道监控',
  'menu.pointer.search.game-ring': '游戏擂台',
  'menu.pointer.search.game-ranking': '游戏排行',
  'menu.pointer.search.other-report': '其他报表',
  'menu.pointer.board': '分析看板',

  'menu.pointer.inquire': '查询系统',
  'menu.pointer.inquire.new-inquire': '新查询系统',
  'menu.pointer.inquire.active': '活跃查询',
  'menu.pointer.inquire.same-time': '同期查询',
  'menu.pointer.inquire.material': '素材查询',

  // 工具
  'menu.tool': '工具',
  'menu.tool.tool': '工具',
  'menu.tool.youtube': 'YouTube素材',
  'menu.tool.youtube-add': '批量素材创建',
  'menu.tool.task-list':'任务列表',
  'menu.tool.useful-toll': '实用工具',
  'menu.tool.status-list':'状态列表',

  // 变现
  'menu.realization': '变现',
  'menu.realization.adunits': '广告单元',

  // 运营系统
  'menu.game-ops': '游戏运营',
  'menu.game-ops.game-ops': '游戏运营',
  'menu.game-ops.user-level': '用户分层',
  'menu.game-ops.user-level-edit': '编辑分层配置',
  'menu.user-level-edit': '编辑分层配置',
  'menu.user-level-view': '查看分层配置',

  'menu.game-ops.point-control': '点位控制',

  // aimirror
  'menu.game-ops.aimirror': 'AIMirror',
  'menu.game-ops.aimirror.draw-history': '渲染队列',
  'menu.game-ops.aimirror.render-history': '视频队列',
  'menu.game-ops.aimirror.home-page-config': '首屏配置',
  'menu.game-ops.aimirror.model-config': '模型列表',
  'menu.game-ops.aimirror.model-config-edit': '编辑模型列表',
  'menu.game-ops.aimirror.home-group-config': '首屏分组配置',
  'menu.game-ops.aimirror.loading-tips-config': 'loading tips 配置',
  'menu.game-ops.aimirror.open-screen-config': '开屏配置',
  'menu.game-ops.aimirror.decoration-config': '装饰配置',
  'menu.game-ops.aimirror.wish-list': '心愿列表',

  // spellai
  'menu.game-ops.spellai': 'SpellAI',
  'menu.game-ops.spellai.draw-history': '渲染队列',
  'menu.game-ops.spellai.model-config': '模型配置',
  'menu.game-ops.spellai.feeds': 'Feeds',
  'menu.game-ops.spellai.feeds-list': 'Feeds列表',
  'menu.game-ops.spellai.draw-history-new': '新渲染队列',
  'menu.game-ops.spellai.feeds-search': 'Feeds搜索',
  'menu.game-ops.spellai.hot-word-stat': '热词分析',


  // mova
  'menu.game-ops.mova': 'MOVA',
  'menu.game-ops.mova.mova-history': '视频队列',
  'menu.game-ops.mova.render-history': '视频队列',

  // 投放
  'menu.promotion': '投放',
  'menu.promotion.promotion': '投放',
  'menu.promotion.batch-promotion-list': '新建广告',
  // 'menu.batch-promotion-list':'新建广告',

  'menu.promotion.batch-promotion-list.unity': '新建广告/Unity',
  'menu.promotion.batch-promotion-list.mintegral': '新建广告/Mintegral',
  'menu.promotion.batch-promotion-list.applovin': '新建广告/Applovin',
  'menu.promotion.batch-promotion-list.tiktok': '新建广告/TikTok',

  'menu.promotion.batch-promotion-list.batch-promotion-add': '建组模版',
  'menu.batch-promotion-list.batch-promotion-add': '建组模版',
  'menu.batch-promotion-add': '建组模版',
  'menu.promotion.batch-promotion-add': '建组模版',

  // 广告列表
  'menu.guanggao-list': '广告列表',
  'menu.promotion.guanggao-list': '广告列表',

  'menu.promotion.guanggao-list.unity': 'Unity游戏列表',
  'menu.promotion.guanggao-list.unity.unity-list': 'Unity广告列表',
  'menu.promotion.guanggao-list.unity.unity-list.unity-game': 'Unity游戏列表',
  'menu.promotion.guanggao-list.unity.unity-list.unity-campaign': 'Unity广告列表',
  'menu.promotion.guanggao-list.unity.unity-list.unity-creativePacks': 'Unity推广列表',

  'menu.promotion.guanggao-list.mintegral': 'Mintegral游戏列表',
  'menu.promotion.guanggao-list.mintegral.mintegral-list': 'Mintegral广告列表',
  'menu.promotion.guanggao-list.mintegral.mintegral-list.mintegral-app': 'Mintegral游戏列表',
  'menu.promotion.guanggao-list.mintegral.mintegral-list.mintegral-campaign': 'Mintegral广告列表',
  'menu.promotion.guanggao-list.mintegral.mintegral-list.mintegral-creative': 'Mintegral推广列表',

  'menu.promotion.guanggao-list.applovin': 'Applovin游戏列表',
  'menu.promotion.guanggao-list.applovin.applovin-list': 'Applovin广告列表',
  'menu.promotion.guanggao-list.applovin.applovin-list.applovin-app': 'Applovin游戏列表',
  'menu.promotion.guanggao-list.applovin.applovin-list.applovin-campaign': 'Applovin广告列表',
  'menu.promotion.guanggao-list.applovin.applovin-list.applovin-creativePacks': 'Applovin推广列表',

  'menu.promotion.batch-promotion-draft': '草稿列表',

  // 智能监控
  'menu.promotion.intelligent-monitoring': '智能监控',
  'menu.promotion.intelligent-monitoring.diagnosis-detail': '诊断详情',
  'menu.promotion.diagnosis-detail': '诊断详情',

  'menu.promotion.intelligent-monitoring.adjust-track': '投放轨迹',
  'menu.promotion.intelligent-monitoring.diagnosis-strategy': '策略列表',
  'menu.intelligent-monitoring.diagnosis-strategy.create': '新增策略',
  'menu.intelligent-monitoring.diagnosis-strategy.edit': '编辑策略',
  'menu.intelligent-monitoring.diagnosis-strategy.view': '查看策略',

  //人力资源
  'menu.hr': '人力资源',
  'menu.hr.hr': '人力资源',
  'menu.hr.my-appraisal': '我的考评',
  'menu.hr.appraisal-list': '考评列表',
  'menu.my-appraisal': '我的考评',
  'menu.appraisal-list': '考评列表',
  'menu.hr.point-list': '积分列表',
  'menu.settings': '个人设置',
  'menu.emp-career': '员工生涯',
  'menu.point': '积分记录',
};
