import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { Badge } from 'antd';
import type { RunTimeLayoutConfig } from 'umi';
import { history, Link, useRouteMatch } from 'umi';
import RightContent from '@/components/RightContent';
import { currentUser as queryCurrentUser, queryMenuData } from './services/ant-design-pro/api';
import { getUntreatedCount } from './services/common';
import {
  LinkOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TagsOutlined,
  TagOutlined,
} from '@ant-design/icons';
import transformMenuItem from './utils/fixMenuItemIcon';

const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';
/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://v3.umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  disablePageTitle: false | undefined;
  settings?: Partial<LayoutSettings>;
  isNewPageOpen?: boolean;
  collapsed?: boolean;
  currentUser?: API.CurrentUser;
  fetchUntreatedCount?: () => Promise<string | number>;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  fetchMenuData?: () => Promise<any>;
  menuData: any;
  untreatedCount: number;
}> {
  const fetchUntreatedCount = async () => {
    try {
      const msg = await getUntreatedCount();
      return msg.data;
    } catch (error) {}
    return undefined;
  };
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  const fetchMenuData = async () => {
    try {
      const menuData = await queryMenuData();
      return menuData.data;
    } catch (error) {
      history.push('/user/login');
    }
    return undefined;
  };
  let path = location.pathname;
      if(path.endsWith('/')){
        path = path.slice(0, path.length - 1)
      }
  // 如果是登录页面，不执行
  if (path !== loginPath) {
    const untreatedCount = await fetchUntreatedCount();
    const currentUser = await fetchUserInfo();
    const menuData = await fetchMenuData();
    return {
      collapsed:
        process.env.NODE_ENV === 'development'
          ? false
          : !!parseInt(localStorage.getItem('pubPageCollapsed') || '1'),
      isNewPageOpen: !!parseInt(localStorage.getItem('pubPageOpenNew') || '1'),
      disablePageTitle: undefined,
      fetchUntreatedCount,
      fetchUserInfo,
      fetchMenuData,
      currentUser,
      settings: {},
      menuData,
      untreatedCount,
    };
  }
  return {
    collapsed:
      process.env.NODE_ENV === 'development'
        ? false
        : !!parseInt(localStorage.getItem('pubPageCollapsed') || '1'),
    isNewPageOpen: !!parseInt(localStorage.getItem('pubPageOpenNew') || '1'),
    disablePageTitle: undefined,
    untreatedCount: 0,
    menuData: [],
    fetchUntreatedCount,
    fetchUserInfo,
    fetchMenuData,
    settings: {},
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {

  return {
    // pageTitleRender: initialState?.disablePageTitle,
    /** 页面标签渲染 */
    // pageTitleRender: (data)=>{
    //   console.log('data', data)
    //   return `${data.breadcrumb && data.pathname && data.breadcrumb[data.pathname] && data.breadcrumb[data.pathname].name && data.breadcrumb[data.pathname].name + '-'}RIVEROLL`
    // },
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    /** 水印 */
    waterMarkProps: {
      content: initialState?.currentUser?.userName,
    },
    collapsed: initialState?.collapsed,
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      let path = location.pathname;
      if(path.endsWith('/')){
        path = path.slice(0, path.length - 1)
      }

      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && path !== loginPath) {
        history.push(loginPath);
      }
      if (path.startsWith('/bi')) {
        setInitialState((s: any) => ({ ...s, collapsed: true, disablePageTitle: false }));
      }
    },
    // 折叠按钮渲染
    collapsedButtonRender: (collapsed) => {
      return (
        <>
          <div
            onClick={() => {
              localStorage.setItem('pubPageCollapsed', (!collapsed ? 1 : 0).toString());
              setInitialState((s: any) => ({ ...s, collapsed: !collapsed }));
              setInitialState((s: any) => ({
                ...s,
                disablePageTitle: !collapsed ? false : undefined,
              }));
            }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </>
      );
    },
    /** 菜单数据转化 */
    menuDataRender: () => {
      return transformMenuItem(
        initialState ? initialState.menuData : [],
        0,
        'Outlined',
        initialState?.collapsed,
      );
    },
    // menuRender: (props) => {
    //   console.log('props', props)
    // },
    /** 子菜单渲染，加红点 */
    subMenuItemRender: (menuItemProps, defaultDom) => {
      const infoVO = initialState?.currentUser?.appraisalTodoCountInfoVO;
      const shouldNotify =
        infoVO?.myCommunication !== 0 ||
        infoVO?.waitCommunication !== 0 ||
        infoVO?.waitFeedback !== 0 ||
        infoVO?.waitSelfAssessment !== 0 ||
        infoVO?.waitAppraisal !== 0 ||
        infoVO?.waitConfirm !== 0;
      return (
        <>
          {defaultDom}
          {menuItemProps.path === '/hr' &&
            initialState?.currentUser &&
            initialState?.currentUser.appraisalTodoCountInfoVO &&
            shouldNotify && (
              <Badge
                dot
                offset={initialState?.collapsed ? [-65, -5] : [5, -5]}
                style={{ width: '7px', height: '7px' }}
              />
            )}
        </>
      );
    },
    /** 菜单渲染，加红点 */
    menuItemRender: (menuItemProps, defaultDom, BaseMenuProps) => {
      const infoV0 = initialState?.currentUser?.appraisalTodoCountInfoVO;
      return (
        <Link
          to={menuItemProps.path || '/welcome'}
          target={
            process.env.NODE_ENV === 'development' || !initialState?.isNewPageOpen
              ? '_self'
              : '_blank'
          }
        >
          {defaultDom}
          {menuItemProps.path === '/promotion/intelligent-monitoring' &&
            initialState?.untreatedCount !== 0 && (
              <Badge
                count={initialState?.untreatedCount}
                overflowCount={999}
                style={{
                  position: 'relative',
                  right: initialState?.collapsed ? '-40px' : '-60px',
                  backgroundColor: '#41f28',
                }}
              />
            )}
          {((menuItemProps.path === '/hr/my-appraisal' &&
            initialState?.currentUser &&
            infoV0 &&
            (infoV0.myCommunication !== 0 || infoV0.waitSelfAssessment !== 0)) ||
            (menuItemProps.path === '/hr/appraisal-list' &&
              initialState?.currentUser &&
              infoV0 &&
              (infoV0.waitCommunication !== 0 ||
                infoV0.waitFeedback !== 0 ||
                infoV0.waitAppraisal !== 0 ||
                infoV0.waitConfirm !== 0))) && (
            <Badge dot offset={[5, -5]} style={{ width: '7px', height: '7px' }} />
          )}
          
        </Link>
      );
    },
    breadcrumbRender: (routers = []) => {
      return routers.length > 1
        ? routers
        : [
            {
              path: '/',
              breadcrumbName: '首页',
            },
            ...routers,
          ];
    },
    itemRender: (route, params, routes, paths) => {
      // 渲染面包屑
      const noToPath = routes.indexOf(route) === 0 || routes.indexOf(route) === routes.length - 1;
      return noToPath ? (
        <span>{route.breadcrumbName}</span>
      ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
      );
    },
    contentStyle: { margin: '0px' },
    contentClass: 'qqqq',
    links: [
      <a href="https://bi.riveroll.top/webroot/home.html" rel="noopener" target="_blank">
        <LinkOutlined />
        <span>永恒指针</span>
      </a>,
      <a
        onClick={() => {
          localStorage.setItem('pubPageOpenNew', (!initialState?.isNewPageOpen ? 1 : 0).toString());
          setInitialState((s: any) => ({ ...s, isNewPageOpen: !initialState?.isNewPageOpen }));
        }}
      >
        {initialState?.isNewPageOpen ? (
          <TagsOutlined style={{ color: initialState?.collapsed ? '#1890ff' : 'initial' }} />
        ) : (
          <TagOutlined />
        )}
        <span>{initialState?.isNewPageOpen ? '新标签页' : '当前标签页'}</span>
      </a>,
    ],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    ...initialState?.settings, 
  };
};
