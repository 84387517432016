// 时间范围工具

import moment, { Moment } from 'moment';
import {
  LAST_MONTH,
  LAST_WEEK,
  LAST_TWO_WEEK,
  LAST_YEAR,
  NEXT_MONTH,
  NEXT_TWO_MONTH,
  NEXT_TWO_WEEK,
  NEXT_WEEK,
  TODAY,
  TOMORROW,
  YESTERDAY,
  DefaultDateNoTimeFormat,
  DefaultDateFormat
} from '@/utils/properties';

type Ranges = Record<string, Moment[]>;

const Today = moment(TODAY);
const Yesterday = moment(YESTERDAY);
const LastWeek = moment(LAST_WEEK);
const LastTwoWeek = moment(LAST_TWO_WEEK);
const LastMonth = moment(LAST_MONTH);
const LastYear = moment(LAST_YEAR);
const Tomorrow = moment(TOMORROW);
const NextWeek = moment(NEXT_WEEK);
const NextTowWeek = moment(NEXT_TWO_WEEK);
const NextTowMonth = moment(NEXT_TWO_MONTH);
const NextMonth = moment(NEXT_MONTH);
// eslint-disable-next-line @typescript-eslint/no-redeclare
const Ranges: Ranges = {
  today: [Today, Today],
  tomorrow: [Today, Tomorrow],
  tomorrowOnly: [Tomorrow, Tomorrow],
  yesterday: [Yesterday, Today],
  yesterdayOnly: [Yesterday, Yesterday],
  nextWeek: [Today, NextWeek],
  lastWeek: [LastWeek, Today],
  lastTwoWeek: [LastTwoWeek, Today],
  nextMonth: [Today, NextMonth],
  lastMonth: [LastMonth, Today],
  lastYear: [LastYear, Today],
};

/**
 * 判断今天/昨天/前天/其他时间
 */

export const dateToRangeStr = (date: string) => {
  try {
    let oneDay = moment(new Date()).format(DefaultDateFormat);
    let twoDay = moment(new Date().getTime() - 60*60*24*1000).format(DefaultDateFormat);
    let threeDay = moment(new Date().getTime() - 60*60*24*1000*2).format(DefaultDateFormat);
    let currentDay = moment(new Date(date)).format(DefaultDateFormat);

    if(currentDay === oneDay) {
      return '今天'
    }else if(currentDay === twoDay) {
      return '昨天'
    }else if(currentDay === threeDay) {
      return '前天'
    }else if((new Date().getTime() - new Date(date).getTime()) / (24 * 60 * 60 * 1000) <= 10 ) {
      return `${Math.ceil((new Date().getTime() - new Date(date).getTime()) / (24 * 60 * 60 * 1000))}天前`
    }else if(new Date(date).toString() !== 'Invalid Date') {
      return currentDay
    }else {
      return '-'
    }
  } catch (error) {
    return '-'
  }
  
 }
 
export {
  Today,
  Yesterday,
  LastWeek,
  LastMonth,
  Tomorrow,
  NextWeek,
  NextMonth,
  LastYear,
  NextTowWeek,
  NextTowMonth,
};

export default Ranges;
